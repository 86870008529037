
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthActions, IAuthAction, AuthObserver, AuthService } from 'ionic-appauth';
import { Subscription } from 'rxjs';
import { LoadDataService } from 'src/app/services/load-data.service';

@Component({
  templateUrl: 'signin-callback.page.html'
})
export class SigninCallbackPage implements OnInit, OnDestroy {
  sub: Subscription;

  constructor(
    private auth: AuthService,
    private navCtrl: NavController,
    private router: Router,
    private loadData: LoadDataService,
  ) { }

  ngOnInit() {
    console.log('SignIn ngOnInit');
    this.sub = this.auth.events$.subscribe((action) => this.postCallback(action));
    this.auth.authorizationCallback(window.location.origin + this.router.url);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  postCallback(action: IAuthAction) {
    console.log('SignIn postCallback');
    console.log(action);
    this.navCtrl.navigateRoot('navigator/home');
  }

}
