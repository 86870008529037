import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class MockService{

    private mock: Object = null;

    constructor(private http: HttpClient) {}

    public getMock(){
        return this.mock;
    }

    public load() {
        if(environment.loadMocks){
            return new Promise((resolve, reject) => {
                this.http.get<any>('./assets/mock.json')
                    .subscribe((response) => {
                        this.mock = response;
                        resolve(true);
                    },
                    (error) => {
                        console.log('No mock file added');
                        console.log(error);
                        resolve(true);
                });
            });
        }

    }
}