import { Inject, Injectable, Injector } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoadDataService } from './load-data.service';
import { AppConfig } from './app-config.service';
import { switchMap } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  private _apiUrl: string = null;

  constructor(
    private loaddata: LoadDataService,
    private config: AppConfig,
    private injector: Injector
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var modifiedReq: HttpRequest<any>;

    if (this.typeOf(req, RequestType.API)) {
      modifiedReq = req.clone({
        url: req.url.replace(RequestType.API, this.apiUrl),
        headers: req.headers
          .set('Device-Id', this.loaddata.uuid)
          .set('Authorization', this.config.getConfig("api_key")),
      });
      return next.handle(modifiedReq);
    }

    if (this.typeOf(req, RequestType.MIBA) && this.config.getConfig("auth_proxy") != "") {
      modifiedReq = req.clone({
        url: this.config.getConfig("auth_proxy"),
        headers: req.headers.append('url', req.url)
      });
      return next.handle(modifiedReq);
    }

    if (this.typeOf(req, RequestType.MIBATOKEN) && this.shouldUseSecret()) {
      let http: HttpClient = this.injector.get(HttpClient);
      var url = this.config.getConfig("api_url") + "/mibaconfig";
      return http.get<any>(url)
        .pipe(switchMap(x => {
          var body = req.body;
          body = body + "&client_secret=" + x.secret;
          modifiedReq = req.clone({
            body: body
          });
          return next.handle(modifiedReq);
        }));
    }
    return next.handle(req);
  }

  private typeOf(request: HttpRequest<any>, type: string): boolean {
    return request.url.indexOf(type) !== -1;
  }

  private get apiUrl() {
    if (this._apiUrl == null)
      this._apiUrl = this.config.getConfig("api_url");
    return this._apiUrl;
  }

  private shouldUseSecret(): boolean{
    var openIdHost = this.config.getConfig("auth_config.server_host") as string;
    console.log(openIdHost);
    var result = openIdHost != null && openIdHost.indexOf("mobile") == -1;
    console.log(result);
    return result;
  }
}

export class RequestType {
  static API: string = '@api';
  static MIBA: string = '/userinfo';
  static MIBATOKEN: string = '/token';
}
