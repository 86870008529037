import { NgModule, NgZone } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, Platform } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { SigninCallbackModule } from './signin-callback/signin-callback.module';
import { SignoutCallbackModule } from './signout-callback/signout-callback.module';
import { Requestor, StorageBackend } from '@openid/appauth';
import { CapacitorBrowser, CapacitorSecureStorage } from 'ionic-appauth/lib/capacitor';
import { NgHttpService } from './services/ng-http.service';
import { AuthService, Browser } from 'ionic-appauth';
import { openIdFactory } from './open-id.factory';
import { AppConfig } from '../services/app-config.service';



@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SigninCallbackModule,
    SignoutCallbackModule
  ],
  providers:[
    {
        provide: StorageBackend,
        useClass: CapacitorSecureStorage
      },
      {
        provide: Requestor,
        useClass: NgHttpService
      },
      {
        provide: Browser,
        useClass: CapacitorBrowser
      },
      {
        provide: AuthService,
        useFactory : openIdFactory,
        deps: [Platform, NgZone, Requestor, Browser, StorageBackend, AppConfig]
      }
  ]
})
export class OpenIdModule {}