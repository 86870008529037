import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';

import { Storage } from '@ionic/storage-angular';
import { CookieService } from 'ngx-cookie-service';
import { Profile } from '../models/profile';
import { BreadCrumbs } from '../models/system';
import { Router } from '@angular/router';
import { ThemeService } from './theme.service';
import { Content, Schedule } from '../models/content';
import { ContentDetails } from '../models/contentDetails';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';

import moment from 'moment'
moment.locale('es');

@Injectable({
  providedIn: 'root'
})
export class LoadDataService {
  
  uuid: string = '';
  fontBig = false;
  onboarding = false;
  
  breadcrumbs: BreadCrumbs[] = [];

  tokenFCM = '';

  notificaciones = [];
  unReadNotifications = 0;

  canGoBack = true;

  selectedTag = '';
  
  constructor(
    private storage: Storage,
    private cookies: CookieService,
    private router: Router,
    private themeService: ThemeService
  ) {
    this.storage.create();

    this.getData('font').then( font => this.fontBig = font );
    this.logDeviceInfo();
    
  }
  
  async getData(key) {
    const keyVal = await this.storage.get(key);
    return keyVal;
  }

  logDeviceInfo = async () => {
    const info2 = await Device.getId();
    this.uuid = info2.identifier;
  };

  setFont(payload?:boolean) {
    this.fontBig = !this.fontBig;
    this.storage.set('font', this.fontBig)
  }

  getFont(): Promise<Profile>{
    return this.storage.get('font');
  }

  setMode() {
    this.storage.set('mode', this.themeService.selectedTheme)
  }

  getMode(){
    return this.storage.get('mode');
  }

  setOB(){
    this.storage.set('ob', true);
  }

  setStorage(clave:string, payload: any) {
    this.storage.set(clave, payload);
  }

  clearStorage() {
    SecureStoragePlugin.clear()
      .then(_ => console.log('secure-storage cleared'));
    this.storage.clear()
      .then(_ => console.log('storage cleared'));;
  }

  getProfile(): Promise<Profile>{
    return this.storage.get('profile');
  }

  setProfile(profile: Profile){
    this.storage.set('profile', profile);
  }

  deleteProfile(){
    this.storage.remove('profile');
  }

  removeData(){
    // this.storage.remove('profile');
    this.cookies.deleteAll();
    // this.storage.remove('perfil');
    this.router.navigate(['/navigator/home'])
  }

  getUnReadNotificationsLength() {
    const urn = this.notificaciones.filter( urn => !urn.isRead)
    this.unReadNotifications = urn.length;
  }

  getSheduleInfo( content: Content ) {

    // Para cuando no tiene ocurrecias o ocurrencias vigentes
    if ( content.schedules === null || content.schedules.length === 0 ) {
      // console.log("sin ocurrencias " + content.title);
      return {
        place: null,
        date: null,
        description: content.shortDescription
      } as ContentDetails
    }

    // Para cuando todas las ocurrencias son virtuales
    if ( content.schedules.every( x => x.place === null) ) {
      // console.log("ocurrencias virtuales " + content.title);
      return {
        place: 'Sala virtual',
        date: content.schedules.length > 1 ? 'Ocurre en diferentes fechas' : this.getDate( content.schedules[0] ),
        description: null
      } as ContentDetails
    }

    // Para cuando todas las ocurrencias suceden en el mismo lugar
    if ( content.schedules.every( x => x.place?.id === content.schedules[0].place?.id ) ) {
      // console.log("ocurrencias mismo lugar " + content.title);
      return {
        place: content.schedules[0].place?.name,
        date: content.schedules.length > 1 ? 'Ocurre en diferentes fechas' : this.getDate( content.schedules[0] ),
        description: null
      } as ContentDetails
    }

    // Para cuando todas las ocurrencias NO suceden en el mismo lugar
    if ( !content.schedules.every( x => x.place?.id === content.schedules[0].place?.id ) ) {
      // console.log("ocurrencias distintos lugares " + content.title);
      return {
        place: 'Se celebra en varios lugares',
        date: content.schedules.every( x => x.dateFrom === content.schedules[0].dateFrom) ? 
                                                              this.getDate( content.schedules[0] ) : 'Ocurre en diferentes fechas',
        description: null
      } as ContentDetails
    }

    console.log("default " + content.title);
    return {
      place: content.schedules[0].place?.name,
      date: this.getDate( content.schedules[0] ),
      description: null
    }


  }

  getDate( schedule: Schedule ) {
    const date = moment(schedule.dateFrom);

    if ( schedule.overrideDateText !== null && schedule.overrideDateText !== '' ) {
      return schedule.overrideDateText
    } else {
      return date.format('DD MMM - HH:mm') + ' hs';
    }
  }
}
