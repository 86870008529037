import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppConfig {

    private config: Object = null;

    constructor(private http: HttpClient) {
    }

    /**
     * Use to get the data found in the second file (config file)
     */
    public getConfig(key: string) {
        var fromExternal = this.getFromJson(key, this.config);
        var fromEnv = this.getFromJson(key, environment);
        if(environment.allowExternalConfig && fromExternal != null && fromExternal != "") 
            return fromExternal;
        if(fromEnv != null) 
            return fromEnv;
        return null;
    }

    public getFromJson(key: string, source: Object){
        if(source == null) return null;
        const props = key.split(".");
        var result = null;
        props.forEach(element => {
            if(result == null) { result = source[element]}
            else {result = result[element]}
        });
        return result;
    }

    public load() {
        console.log("External config: " + environment.allowExternalConfig);
        return new Promise((resolve, reject) => {
            this.http.get<any>('./assets/config.json')
                .subscribe((configResponse) => {
                    this.config = configResponse;
                    resolve(true);
                },
                (error) => {
                    console.log('Configuration file "config.json" could not be read');
                    console.log(error);
                    resolve(true);
            });
        });
    }


}