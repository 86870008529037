import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpInterceptorService } from './services/http-interceptor.service';

import { IonicStorageModule } from '@ionic/storage-angular';
import { OpenIdModule } from './open-id/open-id.module';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx'

import { CookieService } from 'ngx-cookie-service';
// import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { TextToSpeechAdvanced } from '@awesome-cordova-plugins/text-to-speech-advanced/ngx';
// import { TextToSpeech } from '@capacitor-community/text-to-speech';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { AppConfig } from './services/app-config.service';
import { MockService } from './services/mock.service';

registerLocaleData( localeEs, 'es');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule, 
    HttpClientModule, 
    IonicModule.forRoot(), 
    AppRoutingModule, 
    FormsModule,
    IonicStorageModule.forRoot(),
    OpenIdModule
    
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true},
    Geolocation,
    { provide: LOCALE_ID, useValue: 'es'},
    CookieService,
    // BarcodeScanner,
    TextToSpeechAdvanced,
    LocationAccuracy,
    AppConfig,
    MockService,
          { 
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [AppConfig, MockService],
            multi: true 
          }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function initConfig(config: AppConfig, mock: MockService) {
  return () => config.load().then(() => mock.load()) ;
}
