import { Platform } from '@ionic/angular';
import { StorageBackend, Requestor } from '@openid/appauth';
import { AuthService, Browser } from 'ionic-appauth';
import { App } from '@capacitor/app';
import { environment } from 'src/environments/environment';
import { NgZone } from '@angular/core';
import { AppConfig } from '../services/app-config.service';

export let openIdFactory = (platform: Platform, ngZone: NgZone,
    requestor: Requestor, browser: Browser,  storage: StorageBackend, appConfig: AppConfig) => {

    const authService = new AuthService(browser, storage, requestor);
    authService.authConfig = environment.auth_config;

    authService.authConfig.client_id = appConfig.getConfig("auth_config.client_id");
    authService.authConfig.server_host = appConfig.getConfig("auth_config.server_host");
    authService.authConfig.client_secret = appConfig.getConfig("auth_config.client_secret"); 
    authService.authConfig.redirect_url = appConfig.getConfig("auth_config.redirect_url");
    authService.authConfig.end_session_redirect_url = appConfig.getConfig("auth_config.end_session_redirect_url");
    authService.authConfig.scopes = appConfig.getConfig("auth_config.scopes");

    if (!platform.is('cordova')) {
        authService.authConfig.redirect_url = window.location.origin + '/auth/callback';
        authService.authConfig.end_session_redirect_url = window.location.origin + '/auth/endsession';
    }

    if (platform.is('capacitor')) {
        App.addListener('appUrlOpen', (data: any) => {
            if (data.url !== undefined) {
                ngZone.run(() => {
                    if ((data.url).indexOf(authService.authConfig.redirect_url) === 0) {
                        authService.authorizationCallback(data.url);
                    }else{
                        authService.endSessionCallback();
                    }
                });
            }
        });
    }

    return authService;
};

