import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, RendererFactory2, Renderer2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  renderer: Renderer2;
  selectedTheme = 'ligh-theme';

  constructor(
    private renderFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.renderer = this.renderFactory.createRenderer(null, null)
  }

  enabledHighContrastMode() {
    this.renderer.addClass(this.document.body, 'high-contrast-theme');
    this.selectedTheme = 'high-contrast-theme';
  }

  enabledLightMode() {
    this.renderer.removeClass(this.document.body, 'high-contrast-theme');
    this.selectedTheme = 'ligh-theme';
  }

}
