export const environment = {
  production: false,
  allowExternalConfig: true,
  loadMocks: false,
  
  api_url: "",
  public_url: "",
  api_key: "",
  auth_config: {
    client_id: "",
    server_host: "",
    redirect_url: "",
    end_session_redirect_url: "",
    scopes: "",
    pkce: true
  },
  auth_proxy: "",
  app_url: "",
  bienestar_id: "",
  firebaseConfig : {
    apiKey: "AIzaSyDGJEJ5d8tB8G8-j0Hixj3bIL7UK8ExgbI",
    authDomain: "club-simple.firebaseapp.com",
    projectId: "club-simple",
    storageBucket: "club-simple.appspot.com",
    messagingSenderId: "439393821771",
    appId: "1:439393821771:web:4160f214abeea75ee899ce",
    measurementId: "G-697VRXCM9K"
  }
};