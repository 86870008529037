import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AuthService } from 'ionic-appauth';
import { Router } from '@angular/router';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  templateUrl: 'signout-callback.page.html'
})
export class SignoutCallbackPage implements OnInit {

  constructor(
    private auth: AuthService,
    private navCtrl: NavController
  ) { }

  ngOnInit() {
    this.auth.endSessionCallback();
    this.navCtrl.navigateRoot('navigator/home');
  }

}
