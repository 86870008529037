import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { Device } from '@capacitor/device';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private router: Router
  ) {
    this.initFb();

    this.router.events.pipe(
      filter( (e: RouterEvent) => e instanceof NavigationEnd )
    ).subscribe( (e: RouterEvent) => {
      console.log( 'route changed: ', e.url);
      if ( e.url && e.url !== '/' ) {
        this.setScreenName(e.url)
      }
    })
    
  }

  async initFb() {

    if ( ( await  Device.getInfo() ).platform == 'web' ) {
      FirebaseAnalytics.initializeFirebase( environment.firebaseConfig ).then( resp => console.log('fbInit', resp));
    }

  }

  setScreenName( screenName ) {
    FirebaseAnalytics.setScreenName({
      screenName
    })
  }

  setUser( user: string ) {
    // Use Firebase Auth uid
    FirebaseAnalytics.setUserId({
      userId: user,
    });
  }

  logEvent( eventName: string, params: {} ) {
    FirebaseAnalytics.logEvent({
      name: eventName,
      params: params
    });
  }
}
