import { Component, Inject, Renderer2, NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AuthService } from 'ionic-appauth';
import { LoadDataService } from './services/load-data.service';
import { ProfileService } from './services/profile.service';
import {SplashScreen} from '@capacitor/splash-screen';
import { NotificationsService } from './services/notifications.service';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';
import { ThemeService } from './services/theme.service';
import { DOCUMENT } from '@angular/common';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Router } from '@angular/router';
import { AppConfig } from './services/app-config.service';
import { TextZoom } from '@capacitor/text-zoom'
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private platform: Platform,
    public loadData: LoadDataService,
    private profileService: ProfileService,
    private auth: AuthService,
    private notificationsService: NotificationsService,
    private locationAccuracy: LocationAccuracy,
    private themeService: ThemeService,
    private zone: NgZone,
    private router: Router,
    private appConfigService: AppConfig
  ) {}

  ngOnInit() {
    this.initializeApp();
  }

  initializeApp() {

    // TextZoom.getPreferred().then( (val: GetResult) => {
    //   console.log( val.value );
    // })
    if (Capacitor.isPluginAvailable('TextZoom')) {
      TextZoom.set({
        value: 1
      });
    }

    App.getLaunchUrl().then ( data => {
      if ( data && data.url) {
        const domain = this.appConfigService.getConfig( 'app_url' );
				const pathArray = data.url.split(domain);
        const appPath = pathArray.pop();

				if (appPath) {
					this.router.navigateByUrl(appPath);
				}
      }
    } );

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
			this.zone.run(() => {

        // alert('appUrlOpen')
				const domain = this.appConfigService.getConfig( 'app_url' );

				const pathArray = event.url.split(domain);
				// The pathArray is now like ['https://devdactic.com', '/details/42']

				// Get the last element with pop()
				const appPath = pathArray.pop();
				if (appPath) {
					this.router.navigateByUrl(appPath);
				}
			});
		});
    
    this.platform.ready().then( async () => {

      this.enabledGPS();
      
      setTimeout(() => {
        // SplashScreen.hide();
      }, 2000);
      await this.auth.init();

      // Cargo el theme mode seleccionado
      this.loadData.getMode().then( mode => {
        console.log('mode', mode)
        this.themeService.selectedTheme = mode;
        mode === 'high-contrast-theme' ? this.themeService.enabledHighContrastMode() : this.themeService.enabledLightMode();
      })
      
      // await this.profileService.init();
      // if (this.platform.is('mobileweb') ||  this.platform.is('desktop') ) {
        this.profileService.init();
      // }
      this.notificationsService.initPush();

      setTimeout(() => {
        if(this.profileService && this.profileService.profile)
        {
        this.notificationsService.countNotification(this.profileService.profile.id)
            .subscribe( (resp:any) => {
              console.log(resp)
              this.loadData.unReadNotifications = resp.Total;
              // const urn = this.loadData.notificaciones.filter( urn => !urn.isRead)
              // this.loadData.unReadNotifications = urn.length;
              // this.notificationsService.countNotification()
            }) 
        }
      }, 2000); 
    });
  }

  enabledGPS() {
    this.locationAccuracy.canRequest().then((canRequest: boolean) => {

      if(canRequest) {
        // the accuracy option will be ignored by iOS
        this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
          () => console.log('GPS Request successful'),
          error => console.log('GPS Error requesting location permissions', error)
        );
      }
    
    });
  }

  exitCamera() {
    
    document.querySelector('ion-router-outlet').classList.remove('scanner-active');
    document.querySelector('ion-spinner').classList.remove('scanner-active');
    
    const btnCancel = document.querySelector<HTMLElement>('.btn-scanner-inactive');
    btnCancel.style.display = 'none';

    const qrBox = document.querySelector<HTMLElement>('.qr-box');
    qrBox.style.display = 'none';
  }
}
