import { ChangeDetectorRef, Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { LoadDataService } from './load-data.service';
import { Router } from '@angular/router';
import { RequestType } from "./http-interceptor.service";

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { HttpClient } from '@angular/common/http';
import { ProfileService } from './profile.service';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  notificationCount = 0;
  notificationCount$: Subject<Number>;

  constructor(
    private loadDataService: LoadDataService,
    private profileService: ProfileService,
    private router: Router,
    private httpClient: HttpClient,
  ) {
    this.notificationCount$ = new Subject();
  }

  public initPush() {
    if ( Capacitor.isPluginAvailable('PushNotifications') ) {
      this.registerPush();
    }
  }

  private async registerPush() {
    
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });
    }

    // if (permStatus.receive !== 'granted') {
    //   throw new Error('User denied permissions!');
    // }

    // await PushNotifications.register();

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        console.log('Push registration success, token: ' + token.value);
        this.loadDataService.tokenFCM = token.value;

        // let actualProfile = this.profileService.getProfile();
        // // alert(actualProfile.id)
        // // alert(actualProfile.notificationToken)
        // alert('token notification register ' +token.value )
        // if ( actualProfile && token.value !== actualProfile.notificationToken) {
        //   // alert( "Envia el update")
        //   this.profileService.updateProfile(actualProfile) 
        // }
        // pregunto si es el mismo
        // si es diferente llamo updateProfile()

        this.profileService.init();
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        // alert('Error on registration: ' + JSON.stringify(error));
        this.profileService.init();
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('Push not received: ' + JSON.stringify(notification) );
        this.countNotification(this.profileService.profile.id)
            .subscribe((resp:any) => {
              console.log('sin leer', resp)
              this.notificationCount$.next(resp.Total);
              // this.loadDataService.unReadNotifications = resp.Total;
            })
        // console.log(notification.data );
        // this.loadDataService.notificaciones.push( notification.data )
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => { 
        console.log('Push action performed: ' + JSON.stringify(notification));
        this.router.navigate(['/navigator/notifications-inside', notification.notification.data.id ])
      }
    );

  }

  getNotificationCount$(): Observable<Number>{
    return this.notificationCount$.asObservable();
  }

  public getNotifications( profileId ){
    const opt = {
        profileId
      }
    return this.httpClient.post(`${RequestType.API}/profileNotification`, opt )
  }

  public getNotification( notificationId ){
    const opt = {
      notificationId
      }
    return this.httpClient.post(`${RequestType.API}/notification`, opt )
  }

  public readNotification( notificationId, profileId ) {
    const opt = {
      notificationId,
      profileId
      }
    return this.httpClient.post(`${RequestType.API}/notificationRead`, opt )
  }

  public countNotification( profileId ) {
    const opt = {
      profileId
      }
    return this.httpClient.post(`${RequestType.API}/countNotification`, opt )
  }
}
