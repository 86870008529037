import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoadingController, NavController} from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { AuthActions, AuthService, IAuthAction } from "ionic-appauth";
import { GetContentsRequest } from "../models/getContentsRequest";
import { Profile } from "../models/profile";
import { AppConfig } from "./app-config.service";
import { RequestType } from "./http-interceptor.service";
import { LoadDataService } from "./load-data.service";
import { MockService } from "./mock.service";
import { AnalyticsService } from "./analytics.service";
import { Router } from "@angular/router";
import { Location } from '@angular/common'

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    authEvents$ = this.authService.events$;
    profile: Profile;
    loading: HTMLIonLoadingElement;

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient, 
        private loadData: LoadDataService,
        private navCtrl: NavController,
        private router: Router,
        private loadingCtrl: LoadingController,
        private config: AppConfig,
        private mock: MockService,
        private analyticsService: AnalyticsService,
        private location: Location
    ) {
            this.authEvents$.subscribe(x => this.onAuthEvent(x));
    }

    public init(){
        console.log('profile init')
        this.loadData.getProfile().then(x => 
            {
                console.log(x);

                if(x == null){
                    this.setProfile(this.annonymusProfile());
                }
                else{
                    this.profile = x;
                    if ( this.loadData.tokenFCM !== this.profile.notificationToken ) {
                        this.profile.notificationToken = this.loadData.tokenFCM;
                        this.updateProfile(this.profile);
                    }
                }
            });
    }

    public async signIn(contentIdRedirect: number = null) {
        this.setLoading();
        this.loadData.setStorage("contentIdRedirect", contentIdRedirect);
        this.authService.signIn();
    }

    public signOut() {
        this.setLoading();
        this.clearProfile();
        this.authService.signOut();
        this.loadData.deleteProfile()
    }

    public anonymousSignIn(){
        this.setProfile(this.annonymusProfile());
    }

    public isAuthenticated(): boolean{
        return this.profile?.baId != null;
    }

    private async onAuthEvent(authAction: IAuthAction){
        console.log('onAuthEvent');
        console.log(authAction);
        if (authAction.action == AuthActions.SignInSuccess  || authAction.action == AuthActions.LoadTokenFromStorageSuccess){
            if(this.profile?.baId != null){ //if ba user is already loaded, don´t do it again.
                console.log('user already loaded');
            }
            else{
                setTimeout( () => {this.authService.loadUserInfo()}, 500 ); //wait for token being stored in storage.
            }
        }
        if (authAction.action == AuthActions.LoadUserInfoSuccess){
            if(this.config.getConfig("auth_proxy") != '')
                await this.setProfile(this.mapMiBAUser(authAction.user));
            else
                await this.setProfile(this.mockProfile(authAction.user.sub));

                await this.loadData.getData("contentIdRedirect").then(contentRedirect => {
                    console.log( contentRedirect )
                    if(contentRedirect)
                        setTimeout(() => {
                            // this.navCtrl.navigateRoot('navigator/content/' + contentRedirect);
                            // this.location.replaceState( path: 'navigator/content/' + contentRedirect );
                            this.router.navigateByUrl('navigator/content/' + contentRedirect, { skipLocationChange: true });
                        }, 1000);
                    else
                        this.navCtrl.navigateRoot('navigator/home');
                    }
    
                    
                );
        }
        
        if( authAction.action == AuthActions.SignOutSuccess ){
            this.navCtrl.navigateRoot('navigator/home');    
        }   
        if(authAction.action == AuthActions.SignInSuccess ){
            console.log('Sign In success')
            // this.navCtrl.navigateRoot('navigator/home'); 
        }   
        
        this.dismissLoading();  
    }

    public setProfile(user: any){
        console.log(user)
        this.httpClient.post(`${RequestType.API}/getProfile`, user)
            .subscribe(x => {
                user = x;
                console.log(user)
                this.loadData.setProfile(user);
                this.profile = user;
            },error => console.log(error));
    }

    private mapMiBAUser(user: any): Profile{
        this.analyticsService.setUser( user.email );

        this.analyticsService.logEvent( 'login', { content_id: user.email  } )

        var profile = new Profile();
        profile.deviceId = this.loadData.uuid;
        profile.baId = user.baid;
        profile.firstName = user.first_name;
        profile.lastName = user.last_name;
        profile.birthDate = user.birthdate;
        profile.documentType = user.document_type;
        profile.documentNumber = user.document_number;
        profile.gender = user.gender;
        profile.email = user.email;
        profile.notificationToken = this.loadData.tokenFCM;
        profile.deviceType =  Capacitor.getPlatform();
        profile.allowNotification = true;
        if(user.phones != null && user.phones.length > 0){
            var phone = user.phones[0];
            profile.phoneNumber = phone.area_code + " " + phone.phone_number;
        }
        return profile;
    }

    public updateProfile(user: any ){
        this.setProfile(user);
    }

    public getProfile(): Profile{
        return this.profile;
    }

    public getTagsProfile(){
        return this.httpClient.post(`${RequestType.API}/profileTags`, {profileId:this.profile.id})
    }

    public setTagsProfile( body ){
        return this.httpClient.post(`${RequestType.API}/setTagsForProfile`, body )
    }

    public deleteImageProfile(){

        const opt = {
            profileId: this.profile.id,
        }
        return this.httpClient.post(`${RequestType.API}/delProfileImage`, opt )
    }

    public clearProfile(){
        this.setProfile(this.annonymusProfile());
    }

    private async setLoading(){
        this.loading = await this.loadingCtrl.create({
            message: 'Cargando...',
            duration: 5000,
          });
        this.loading.present();
    }

    private dismissLoading(){
        if(this.loading)
            this.loading.dismiss();
    }

    public profileImage( image: string) {
        const opt = {
            profileId: this.profile.id,
            image
        }

        return this.httpClient.post(`${RequestType.API}/profileImage`, opt )

    }

    public botiProfile( ) {
        const opt = {
            profileId: this.profile.id
        }

        return this.httpClient.post(`${RequestType.API}/botiProfile`, opt )
    }

    public deleteProfile(){
        const opt = {
            profileId: this.profile.id,
        }

        return this.httpClient.post(`${RequestType.API}/delProfile`, opt )
    }

    public allowNotification(){
        const opt = {
            profileId: this.profile.id,
        }
        return this.httpClient.post(`${RequestType.API}/allowNotification`, opt )
    }

    public blockNotification(){
        const opt = {
            profileId: this.profile.id,
        }
        return this.httpClient.post(`${RequestType.API}/blockNotification`, opt )
    }
    
    private mockProfile(id: any): Profile{
        var profile = this.mock.getMock() as Profile;

        profile.id = null;
        profile.baId = id;
        profile.deviceId = this.loadData.uuid;
        profile.notificationToken = this.loadData.tokenFCM;
        profile.documentNumber = id;
        return profile;
    }

    private annonymusProfile(): Profile{
        var profile = new Profile();
        profile.deviceId = this.loadData.uuid;
        profile.notificationToken = this.loadData.tokenFCM;
        profile.deviceType = Capacitor.getPlatform();
        profile.allowNotification = true;
        return profile;
    }

    public setCoordenadas( body ){
        return this.httpClient.post(`${RequestType.API}/setCoordenadas`, body )
    }

    /* Desde acá hay que sacarlo del servicio de profile y pasarlo a un nuevo "content.service"*/
    public getContainer( code ){
        const opt = {
            code,
            parentId: ""
        }
        return this.httpClient.post(`${RequestType.API}/container`, opt )
    }

    public getPath( code ){
        const opt = {
            code
        }
        return this.httpClient.post(`${RequestType.API}/getPath`, opt )
    }

    public getContents( params: GetContentsRequest ){
        return this.httpClient.post(`${RequestType.API}/getContents`, params )
    }

    public getContent( id ){
        const opt = {
            contentId : id
          }
        return this.httpClient.post(`${RequestType.API}/content`, opt )
    }

    public addFavorite( opt ) {
        return this.httpClient.post(`${RequestType.API}/addFavorite`, opt )
    }

    public addConsultLogged( opt ) {
        return this.httpClient.post(`${RequestType.API}/addConsultLogged`, opt )
    }

    public addConsultAnonymous( opt ) {
        console.log(opt)
        return this.httpClient.post(`${RequestType.API}/addConsultAnonymous`, opt )
    }

    public getStates(  ) {
        return this.httpClient.post(`${RequestType.API}/states`, {} )
    }

    public getDepartments( opt ) {
        console.log(opt)
        return this.httpClient.post(`${RequestType.API}/departaments`, opt )
    }

    public getCities( opt ) {
        console.log(opt)
        return this.httpClient.post(`${RequestType.API}/cities`, opt )
    }
    /*Hasta acá*/
}