export class Profile{
    id: string;
    baId: string;
    deviceId: string;
    deviceType: string;
    notificationToken: string;
    gender: string;
    firstName: string;
    lastName: string;
    imageUrl: string;
    documentType: string;
    documentNumber: string;
    addressRegion: string;
    addressLocality: string;
    addressStreet: string;
    addressNumber: string;
    addressPostalCode: string;
    addressFloor: string;
    addressApartment: string;
    allowNotification : boolean;
    birthDate: string;
    email:string;
    phoneNumber: string;
}